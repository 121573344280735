import React from "react"
import styled from "styled-components"

import Footer from "../components/footer"
import Layout from "../components/layout"
import { Section, SectionInner } from "../components/section"
import SEO from "../components/adventkalender/seo"


const Buttons = styled.div`
  padding-top: 24px;

  a {
    background-color: ${props => props.theme.greenColor};
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    padding: 12px 24px;

    &:first-child {
      margin-right: 24px;
    }
  }
`


const Section404 = styled(Section).attrs({
  bgAlt: true
})`
  font-size: 18px;
  padding-bottom: 136px;
  padding-top: 24px;
  text-align: left;

  h2 {
    margin-bottom: 16px;
    margin-top: 80px;
  }

  h3 {
    margin-bottom: 16px;
    margin-top: 32px;
  }

  ul {
    padding-left: 20px;
  }
`


const NotFoundPage = () => (
  <Layout>
    <SEO title="404 - Seite nicht gefunden" />

    <Section404>
      <SectionInner>
        <h1>Die Seite, nach der Du gesucht hast, ist gerade nicht auf dem Menü.</h1>
        <p>Versuche eine der folgenden Optionen:</p>
        <ul>
          <li>Bitte gib im oberen Suchfeld Deine Adresse ein, um einen Lieferservice für Deinen Wohnort auszusuchen.</li>
          <li>Starte Deine Suche auf unserer Startseite neu.</li>
          <li>Sollte sich hier ein unbekannter Fehler eingeschlichen haben, nimm bitte Kontakt mit uns auf, damit  wir uns so schnell wie möglich darum kümmern können.</li>
        </ul>

        <Buttons>
          <a href="https://www.mjam.net/kontakt/">Hilfe/Kontakt</a>
          <a href="https://www.mjam.net/kontakt/">Zur Startseite</a>
        </Buttons>
      </SectionInner>
    </Section404>
    <Footer noSearch />
  </Layout>
)

export default NotFoundPage
